// modules
import React, { useState } from 'react'
import Img from 'gatsby-image'
import posed, { PoseGroup } from 'react-pose'
// styles
import styles from './Lightbox.module.scss'
// animations
import { animations } from './LightboxAnimations'

// definitions
const Backdrop = posed.div(animations.Backdrop)
const ImageWrapper = posed.div(animations.ImageWrapper)

function Lightbox({ children, image, title, id }) {
  // state
  const [isActive, setIsActive] = useState(false)
  // handlers
  const handleClick = () => setIsActive(!isActive)
  // jsx
  if (image) {
    return (
      <div onClick={handleClick} style={{ cursor: 'zoom-in' }}>
        {children}
        <PoseGroup flipMove={false}>
          {isActive ? (
            <Backdrop
              key={id}
              className={styles.Backdrop}
              onClick={handleClick}
            >
              <ImageWrapper className={styles.imageWrapper}>
                <Img
                  className={styles.imageWrapper}
                  fluid={image}
                  imgStyle={{ objectFit: 'contain' }}
                  title={title}
                  alt={title}
                />
              </ImageWrapper>
            </Backdrop>
          ) : null}
        </PoseGroup>
      </div>
    )
  } else {
    return null
  }
}

// exports
export default Lightbox
