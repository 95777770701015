// modules
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
// styles
import styles from './index.module.scss'
// components
import Grid from '../components/shared/Grid/Grid'
import SEO from '../components/shared/SEO/SEO'
import Lightbox from '../components/shared/Lightbox/Lightbox'

// definitions
function IndexPage({ data }) {
  const pageTitle = 'New York Variations'
  const paintings = data.allMarkdownRemark.edges

  return (
    <>
      <SEO title={pageTitle} />
      <h1>{pageTitle}</h1>
      {paintings.length ? (
        <Grid layout="fluid">
          {paintings.map(painting => {
            const {
              node: { id, frontmatter },
            } = painting
            const title = frontmatter.title
            const gallery = frontmatter.gallery
            const image = frontmatter.image.childImageSharp.fluid

            if (image && title && gallery) {
              return (
                <Lightbox key={id} image={image} id={id}>
                  <div className={styles.paintingFrame}>
                    <Img fluid={image} title={title} alt={title} />
                  </div>
                  <p className={styles.paintingLegend}>{gallery}</p>
                </Lightbox>
              )
            } else {
              return null
            }
          })}
        </Grid>
      ) : null}
    </>
  )
}

// exports
export default IndexPage

// query
export const indexPageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "./content/paintings/" } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            gallery
            image {
              childImageSharp {
                fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
